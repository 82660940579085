.about-us {
  padding: 20px;
  text-align: center;
}

.about-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto; /* Center the header horizontally */
  padding: 20px; /* Add padding for better spacing */
  background-color: #e3f4f4;
  border-radius: 20px;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 20px;

}

.about-logo-container {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  flex-direction: column; /* Stack elements on mobile */
  width: 95%
}

.about-logo {
  width:25%; /* Scale down on mobile */
}

.about-text-container {
  max-width: 100%; /* Ensure it uses full width on mobile */
  text-align: justify;
  padding: 10px; /* Adjust padding for better spacing */
}

.about-text-container h1 {
  font-size: 1.8em; /* Adjust font size for mobile */
  margin: 0;
  color: #000000;
}

.about-text-container p {
  font-size: 1.1em; /* Adjust font size */
  color: #000000;
  margin-top: 10px;
  line-height: 1.5;
}

.about-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.about-card {
  background-color: #e3f4f4;
  border-radius: 20px;
  padding: 15px; /* Adjust padding */
  
  width: 180px; /* Adjust width for mobile */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.about-card:hover {
  transform: scale(1.05);
}

.about-card h2 {
  font-size: 1.8em; /* Adjust font size */
  color: #1dc4bc;
}

.about-card p {
  font-size: 1.1em; /* Adjust font size */
  color: #1dc4bc;
}

.about-mission-vision {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
}

.about-mission,
.about-vision {
  background-color: #e3f4f4;
  border-radius: 20px;
  padding: 20px;
  width: 90%; /* Make responsive */
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.about-mission h2,
.about-vision h2 {
  font-size: 1.8em; /* Adjust font size */
  color: #000000;
  border-bottom: 2px solid #1dc4bc;
  padding-bottom: 10px;
}

.about-mission p,
.about-vision p {
  font-size: 1.1em; /* Adjust font size */
  color: #000000;
  margin-top: 20px;
}

.about-values {
  margin-top: 40px;
}

.about-values h2 {
  font-size: 1.8em; /* Adjust font size */
  color: #000000;
  margin-bottom: 20px;
}

.values-icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.value-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e3f4f4;
  border-radius: 20px;
  padding: 15px; /* Adjust padding */
  width: 140px; /* Adjust width for mobile */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.value-icon img {
  width: 40px; /* Scale down icons */
  margin-bottom: 10px;
}

.value-icon p {
  font-size: 1.1em; /* Adjust font size */
  color: #000000;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .about-logo {
    width: 60px;
  }

  .about-text-container {
    font-size: 0.9em;
  }

  .about-card {
    width: 120px;
    max-height: 140px;
  }
  .about-card h2{
    font-size: 20px;
  }
  .about-card p{
    font-size: 16px;
  }

  .about-mission,
  .about-vision {
    width: 90%;
    padding: 15px;
  }

  .value-icon {
    width: 120px;
    padding: 10px;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Optional: Add a slight upward movement */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Bring it back to the original position */
  }
}
.about-header,
.about-logo ,
.about-cards,
.about-mission-vision,
.about-values {
  animation: fadeIn 1s ease-in-out; /* 1s duration, ease-in-out timing */
  opacity: 0; /* Ensure the element is hidden before the animation starts */
  animation-fill-mode: forwards; /* Keep the final state of the animation */
}

.about-header {
  animation-delay: 0.2s; /* Delay the animation for a slight staggered effect */
}

.about-logo {
  animation-delay: 0.2s; /* Delay the animation for a slight staggered effect */
}

.about-cards {
  animation-delay: 1.4s;
}

.about-mission-vision {
  animation-delay: 2.6s;
}

.about-values {
  animation-delay: 3.2s;
}
