/* src/pages/admin/OrderDetails.css */

.order-details {
    padding: 20px;
  }
  
  .order-info {
    display: flex;
    gap: 20px;
  }
  
  .left-column {
    flex: 1;
  }
  
  .right-column {
    flex: 1;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    text-align: left;
    padding: 8px;
    font-size: 14px;
  }
  
  thead th {
    background-color: #f0f8ff;
    border-bottom: 2px solid #ddd;
  }
  
  tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .status-update {
    margin-top: 20px;
  }
  
  .status-update label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .status-update select {
    padding: 8px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .status-update button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .status-update button:hover {
    background-color: #0056b3;
  }
  
  .proof-container {
    margin-top: 20px;
  }
  
  .proof-container a {
    color: #2196f3;
    text-decoration: none;
  }
  
  .proof-container a:hover {
    text-decoration: underline;
  }
  