/* src/pages/admin/AdminForm.css */
.admin-edit-product {
  padding: 20px;
  padding-top: 110px;

}

.admin-edit-product h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.product-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.product-form label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.product-form input,
.product-form select,
.product-form textarea {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.product-form button {
  grid-column: span 2;
  padding: 10px;
  font-size: 16px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.product-form button:hover {
  background-color: #333;
}
