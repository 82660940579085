.admin-products {
  padding: 20px;
}

.filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px; /* Increased gap for more spacing */
  margin-bottom: 20px;
}

.filters input,
.filters select,
.filters .create-button {
  padding: 10px; /* Increased padding for larger input and button sizes */
  border: 1px solid #ccc;
  border-radius: 5px;
}

.filters input,
.filters select {
  flex: 1; /* Allow inputs and select to grow */
  width: 250px;
}

.filters .create-button {
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  font-size: 14px;
  border: none;
}

.filters .create-button:hover {
  background-color: #45a049;
}

.product-table {
  width: 100%;
  border-collapse: collapse;
}

.product-table th,
.product-table td {
  border: 1px solid #ddd;
  padding: 10px; /* Increased padding for more space */
  text-align: left;
}

.product-table th {
  background-color: #f2f2f2;
}

.edit-button {
  padding: 8px 15px; /* Increased padding for a larger button */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
}
