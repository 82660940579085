.contact {
  padding: 20px;
  text-align: center;

}

.contact-header {
  margin-bottom: 40px;
}

.contact-header h1 {
  font-size: 2.5em;
  margin: 0;
}

.contact-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  flex-wrap: wrap;
}

.contact-info {
  flex: 1;
  max-width: 400px;
  text-align: left;
}

.contact-info p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons img {
  width: 40px;
  height: 40px;
}

.contact-form {
  flex: 2;
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-form input,
.contact-form textarea {
  width: 100% !important;
  max-width: 100% !important;
  padding: 10px !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  font-size: 1em !important;
  box-sizing: border-box !important;
}


.contact-form button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  font-size: 1em;
  cursor: pointer;
  width: 100%; /* Ensure the button width matches the inputs */
}

.contact-form button:hover {
  background-color: #333;
}

@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
    align-items: center;
  }

  .contact-info,
  .contact-form {
    max-width: 100%;
  }
}
