.home {
  position: relative;
  z-index: 10;

}

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--global-color);
  width: 100%;
  box-sizing: border-box;
}

.hero-content {
  flex: 1;
  max-width: 65%;
  padding-right: 20px;
  padding-left: 100px;
}

.hero-image {
  width: 30%; /* Set the hero image container to take up 50% of the screen */
  height: auto;
  display: flex;
  justify-content: flex-end; /* Align the image to the right side of the container if needed */
  overflow: hidden; /* Ensure the excess of the image doesn't overflow out of the container */
  padding: 0; /* Ensure no padding is applied to the container */
  margin: 0; /* Ensure no margin is applied to the container */
}

.hero-image img {
  width: 100%; /* Increase the size of the image */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image fills the container while keeping the aspect ratio */
  display: block;
  position: relative;
  left: 0%; /* Adjust this value to control how much to shift the image left to center it */
  padding: 0; /* Ensure no padding on the image itself */
  margin: 0; /* Ensure no margin on the image itself */
}



.hero-content h1 {
  color: #000000;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
  text-align: left; /* Center the product name */
}

.hero-content p {
  color: rgba(0, 0, 0);
  font-family: normal;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.hero-content button {
  display: flex;
  width: 210px;
  height: 52px;
  padding: 16px 54px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 62px;
  background: #000;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.frame {
  align-items: center;
  display: inline-flex;
  gap: 32px;
  position: relative;
  padding-top: 30px;
}

.frame .div {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.frame .text-wrapper {
  color: #000000;
  font-family: "Barlow-Bold", Helvetica;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-wrapper-2 {
  color: #00000099;
  font-family: "Barlow-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: -2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .line {
  align-self: stretch;
  object-fit: cover;
  position: relative;
  width: 0.2px;
  height: 68px;
}

.frame .imgn {
  align-self: stretch;
  margin-right: -1px;
  object-fit: cover;
  position: relative;
  width: 40px;
  height: 68px;
}



@media (max-width: 768px) {
  .hero {
    flex-direction: column-reverse; /* This will reverse the order so the image appears first */
    padding: 20px 0;
  }

  .hero-content {
    max-width: 90%;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .hero-content h1 {
    font-size: 32px;
    line-height: 40px;
  }

  .hero-content p {
    font-size: 14px;
    line-height: 20px;
  }

  .hero-content button {
    width: 100%;
    padding: 12px;
  }

  .hero-image {
    margin-bottom: 10px; /* Adds space between the image and the text */
    width: 100%;
  }
  .hero-image img {
    width: 100%; /* Increase the size of the image */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensure the image fills the container while keeping the aspect ratio */
    display: block;
    position: relative;
    left: 0%; /* Adjust this value to control how much to shift the image left to center it */
    padding: 0; /* Ensure no padding on the image itself */
    margin: 0; /* Ensure no margin on the image itself */
  }

  /* Ensure frame elements stay in a row on mobile */
  .frame {
    flex-direction: row; /* Keep the elements in a row */
    gap: 10px; /* Reduce gap for better mobile fit */
    justify-content: center; /* Center the elements */
    width: 100%; /* Ensure it spans the full width */
    padding-top: 20px; /* Adjust padding if needed */
  }

  .frame .div, 
  .frame .imgn {
    align-items: center; /* Center-align items */
    display: flex; /* Ensure the elements stay aligned horizontally */
    flex-direction: column;
    width: auto; /* Prevent elements from shrinking too much */
  }
  .frame .text-wrapper {
    color: #000000;
    font-family: "Barlow-Bold", Helvetica;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  .frame .imgn {
    width: 6px; /* Keep the SVG separator small */
    height: 64px; /* Maintain its height */
  }

  .frame .div {
    text-align: center; /* Center the text */
    margin: 0; /* Remove any extra margins */
  }
}


/* Free Shipping Banner */
.free-shipping-banner {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  font-family: Arial, sans-serif;
  position: relative;
  z-index: 1;
}

/* Sustainability Section */
/* Sustainability Section */
.sustainability {
  background-color: var(--global-color);
  padding: 20px 10px;  /* Reduced padding */
}

.sustainability-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;  /* Reduced max-width */
  max-height: 400px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.sustainability-text {
  flex: 1;
  padding: 15px;  /* Reduced padding */
  color: #000;
}

.sustainability-text h2 {
  font-size: 1.8em;  /* Reduced font size */
  margin-bottom: 15px;  /* Reduced margin */
}

.sustainability-text p {
  font-size: 1em;  /* Reduced font size */
  margin-bottom: 15px;  /* Reduced margin */
}

.sustainability-text button {
  padding: 8px 16px;  /* Reduced padding */
  font-size: 0.9em;  /* Reduced font size */
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.sustainability-text button:hover {
  background-color: #444;
}

.sustainability-image {
  flex: 1;
  overflow: hidden;
  max-width: 50%;
  height: 100%; /* Make sure the container takes the full height */
}

.sustainability-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image fills the container while maintaining aspect ratio */
  display: block; /* Ensure the image is treated as a block element to remove inline spacing */
  border-radius: 0 10px 10px 0;
}

@media (max-width: 768px) {
  .sustainability-container {
    flex-direction: column;
    text-align: center;
    max-width: 100%;
  }

  .sustainability-text {
    padding: 10px;  /* Reduced padding */
  }

  .sustainability-image {
    position: relative;
    max-width: 100%;  /* Ensure the container takes full width */
    height: 300px;  /* Set a specific height to limit the visible area */
    overflow: hidden;  /* Hide overflow to make part of the image visible */
  }

  .sustainability-image img {
    width: 100%;  /* Make sure the image covers the width */
    height: auto;  /* Maintain the image's aspect ratio */
    object-fit: cover;  /* Ensure the image covers the container's width */
    object-position: center 20%;  /* Adjust this to control which part is visible */
  }

  .sustainability-image img {
    border-radius: 10px 10px 0 0;
  }

  .sustainability-text h2 {
    font-size: 1.4em;  /* Reduced font size */
  }

  .sustainability-text p {
    font-size: 0.9em;  /* Reduced font size */
  }

  .sustainability-text button {
    font-size: 0.9em;  /* Reduced font size */
  }
}


/* Product Search Section */
.product-search {
  padding: 40px auto;
  background-color: var(--global-color);
  width: 80%;
  padding-top: 60px;
  margin: 0 auto;
}

/* Container2 Styling */
.container2 {
  background-color: #efefef;
  border-radius: 20px;
  height: 433px;
  overflow: hidden;
  position: relative;
  width: 619.5px;
  margin: 0 auto;
  padding-top: 20px;
  transform-origin: top center;
}

.container2 .text-wrapper {
  color: #000000;
  font-family: "Abhaya Libre ExtraBold-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 28.5px;
  width: 100%;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 35px;
  white-space: nowrap;
  padding-bottom: 40px;
}

.container2 .overlap-group-wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 2px #00000040;
  height: 144.5px;
  left: 32px;
  overflow: hidden;
  position: absolute;
  top: 96px;
  width: 203.5px;
}

.container2 .overlap-group {
  position: relative; /* The image will be positioned relative to this */
  width: 100%; /* Ensure the container takes the full width of its wrapper */
  height: 100%; /* Ensure it takes the full height */
  overflow: hidden; /* Prevent image overflow */
}

.container2 .overlap-group img {
  position: absolute; /* Enable absolute positioning */
  top: 50%; /* Position image's center vertically */
  left: 50%; /* Position image's center horizontally */
  transform: translate(-50%, -50%); /* Center the image within the wrapper */
  width: 100%; /* Adjust the size of the image */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image fills its container while maintaining aspect ratio */
}

.container2 .div {
  color: #ffffff;
  font-family: "Barlow-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: absolute; /* Keep it positioned relative to the container */
  bottom: 10px; /* Position it 10px from the bottom */
  left: 10px; /* Position it 10px from the left */
  white-space: nowrap; /* Prevent text from wrapping */
}


.container2 .overlap-wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 2px #00000040;
  height: 144.5px;
  left: 384px;
  overflow: hidden;
  position: absolute;
  top: 250.5px;
  width: 203.5px;
}

.container2 .overlap {
  position: relative; /* The image will be positioned relative to this */
  width: 100%; /* Ensure the container takes the full width of its wrapper */
  height: 100%; /* Ensure it takes the full height */
  overflow: hidden; /* Prevent image overflow */
}

.container2 .overlap img {
  position: absolute; /* Enable absolute positioning */
  top: 30%; /* Position image's center vertically */
  left: 50%; /* Position image's center horizontally */
  transform: translate(-50%, -50%); /* Center the image within the wrapper */
  width: 100%; /* Adjust the size of the image */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image fills its container while maintaining aspect ratio */
}

.container2 .text-wrapper-2 {
  color: #ffffff;
  font-family: "Barlow-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  height: 21.5px;
  left: 110px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 105.5px;
  white-space: nowrap;
}

.container2 .div-wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 2px #00000040;
  height: 144.5px;
  left: 245.5px;
  overflow: hidden;
  position: absolute;
  top: 96px;
  width: 342px;
}

.container2 .overlap-2 {
  position: relative; /* The image will be positioned relative to this */
  width: 100%; /* Ensure the container takes the full width of its wrapper */
  height: 100%; /* Ensure it takes the full height */
  overflow: hidden; /* Prevent image overflow */
}

.container2 .overlap-2 img {
  position: absolute; /* Enable absolute positioning */
  top: 35%; /* Position image's center vertically */
  left: 50%; /* Position image's center horizontally */
  transform: translate(-50%, -50%); /* Center the image within the wrapper */
  width: 100%; /* Adjust the size of the image */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image fills its container while maintaining aspect ratio */
}

.container2 .text-wrapper-3 {
  color: #ffffff;
  font-family: "Barlow-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: absolute; /* Keep it positioned relative to the container */
  bottom: 10px; /* Position it 10px from the bottom */
  left: 10px; /* Position it 10px from the left */
  white-space: nowrap; /* Prevent text from wrapping */
}

.container2 .overlap-wrapper-2 {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 2px #00000040;
  height: 144.5px;
  left: 32px;
  overflow: hidden;
  position: absolute;
  top: 250.5px;
  width: 342px;
}

.container2 .overlap-3 {
  position: relative; /* The image will be positioned relative to this */
  width: 100%; /* Ensure the container takes the full width of its wrapper */
  height: 100%; /* Ensure it takes the full height */
  overflow: hidden; /* Prevent image overflow */
}

.container2 .overlap-3 img {
  position: absolute; /* Enable absolute positioning */
  top: 40%; /* Position image's center vertically */
  left: 50%; /* Position image's center horizontally */
  transform: translate(-50%, -50%); /* Center the image within the wrapper */
  width: 100%; /* Adjust the size of the image */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image fills its container while maintaining aspect ratio */
}

.container2 .text-wrapper-4 {
  color: #ffffff;
  font-family: "Barlow-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: absolute; /* Keep it positioned relative to the container */
  bottom: 100px; /* Position it 10px from the bottom */
  left: 250px; /* Position it 10px from the left */
  white-space: nowrap; /* Prevent text from wrapping */
}


@media (max-width: 768px) {
  /* Product Search Section */
  .product-search {
    padding: 20px 0;
    background-color: var(--global-color);
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
    text-align: center;
    padding-left: 10px;
  }

  .container2 .text-wrapper {
    color: #000000;
    font-family: "Abhaya Libre ExtraBold-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    width: 100%; 
    left: 0px ;   
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 35px;
    white-space: nowrap;
    padding-bottom: 40px;
  }


  /* General Container Setup */
  .container2 {
    background-color: #efefef;
    border-radius: 10px;
    width: 95%;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap; /* Flexbox layout to stack elements and wrap */
    justify-content: space-between; /* Space between the containers */
    left: -5px;
  }
  .container2 .text-wrapper-4 ,
  .container2 .text-wrapper-2 ,
  .container2 .div {
    color: #000000;
    font-family: "Abhaya Libre ExtraBold-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    width: 40%; 
    left: 0px ;   
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: left;
    top:10px;
    white-space: nowrap;
    padding-bottom: 20px;
  }

  .container2 .text-wrapper-4  {
    color: #000000;
    font-family: "Abhaya Libre ExtraBold-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    width: 80%; 
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top:10px;
    white-space: nowrap;
    padding-bottom: 20px;
  }

  .container2 .text-wrapper-3 {
    color: #000000;
    font-family: "Abhaya Libre ExtraBold-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    width: 100%; 
    margin-left: 10px ;   
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: left;
    top:120px;
    white-space: nowrap;
    padding-bottom: 20px;
  }


  /* Treating Each Container */
  .container2 .overlap-group-wrapper, /* First container */
  .container2 .overlap-wrapper,       /* Second container */
  .container2 .div-wrapper,           /* Third container */
  .container2 .overlap-wrapper-2 {    /* Fourth container */
    width: calc(45% - 10px); /* Two containers per row */
    height: 150px; /* Fixed height */
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 2px #00000040;
    overflow: hidden;
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center;     /* Center content vertically */
  }
  .container2 .overlap-group-wrapper, /* First container */
  .container2 .overlap-wrapper-2 { 
    left: 15px;
  }

  .container2 .div-wrapper, 
  .container2 .overlap-wrapper{
    left: 190px;
  }
  .container2 .overlap-wrapper-2,
  .container2 .overlap-wrapper{
    margin-top: 10px;
  }


  /* Image Handling for Each Container */
  .container2 .overlap-group img,   /* First container image */
  .container2 .overlap img,         /* Second container image */
  .container2 .overlap-2 img,       /* Third container image */
  .container2 .overlap-3 img {      /* Fourth container image */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    object-fit: cover; /* Maintain aspect ratio while filling the container */
    display: block; /* Treat image as a block-level element */
    position: relative; /* No absolute positioning */
  }

  .container2 .overlap-group img,   /* First container image */
  .container2 .overlap img {        /* Second container image */
      position: absolute; /* Enable absolute positioning */
      top: 50%; /* Position image's center vertically */
      left: 50%; /* Position image's center horizontally */
      transform: translate(-50%, -50%); /* Center the image within the wrapper */
      width: 100%; /* Full width, no zoom */
      height: auto; /* Maintain aspect ratio */
      object-fit: cover; /* Ensure the image fills its container while maintaining aspect ratio */
  }
/* Third image - Slight zoom */
.container2 .overlap-2 img {      /* Third container image */
  position: absolute; /* Enable absolute positioning */
  top: 50%; /* Center the image */
  left: 50%; /* Center the image */
  transform: translate(-50%, -50%); /* Center the image within the wrapper */
  width: 110%; /* Zoom to 110% width */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image fills its container while maintaining aspect ratio */
}

/* Fourth image - Fix the excessive zoom */
.container2 .overlap-3 img {      /* Fourth container image */
  position: absolute; /* Enable absolute positioning */
  top: 50%; /* Center the image */
  left: 50%; /* Center the image */
  transform: translate(-50%, -50%); /* Center the image within the wrapper */
  width: 120%; /* Slight zoom to 120%, fix from 1000% */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image fills its container while maintaining aspect ratio */
}
  














  /* Text Overlay for All Containers */
  .container2 .div,
  .container2 .text-wrapper-2,
  .container2 .text-wrapper-3,
  .container2 .text-wrapper-4 {
    font-size: 14px;
    bottom: 10px;
    left: 10px;
    white-space: nowrap;
    position: absolute; /* Position text over the image */
    color: white;
    z-index: 1; /* Ensure text is always on top of the image */
  }
}








/* Testimonials Section */
/* Testimonials Section */
.testimonials {
  padding: 40px 20px;
  background-color: var(--global-color);
  text-align: center;
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.testimonials h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.testimonial-carousel {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
  will-change: transform;

}

.testimonial {
  flex: 0 0 33.33%; /* Three testimonials visible at once */
  box-sizing: border-box;
  padding: 20px;  /* Add padding for the cards */
  background-color: #fff; /* Set white background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for card effect */
  opacity: 1; /* Make all cards fully opaque */
  transform: scale(1); /* Remove scaling effect */
  transition: none; /* Remove any transition effects */
  margin: 0 10px; /* Add margin between testimonials */
  border-radius: 10px; /* Add border radius for rounded corners */
}

.testimonial p {
  color: #333; /* Set text color */
}

@media (max-width: 768px) {
  /* Adjust card size */
  .testimonial {
    flex: 0 0 80%; /* Reduce card width slightly */
    margin: 10px auto; /* Center the cards and add vertical spacing */
    padding: 10px; /* Reduce padding inside the cards */
    margin-left: 10px;
  }

  /* Adjust text size */
  .testimonial p {
    font-size: 14px; /* Reduce text size */
    line-height: 1.4; /* Adjust line spacing for better readability */
  }

  /* Adjust heading size */
  .testimonials h2 {
    font-size: 20px; /* Reduce heading size */
  }
}





.newsletter {
  background-color: #000;
  color: #fff;
  padding: 40px 0;
  text-align: center;
  border-radius: 10px;
  margin: 20px 0;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: -80px;
  z-index: 10;
  align-items: center;
  margin-bottom: -250px; /* Adjust for mobile overlap */

}

.newsletter-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  align-items: center;
}

.newsletter h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.newsletter-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.newscont {
  background-color: #fff;
  border-radius: 20px 20px 20px 20px;
}

.newsletter-form input[type="email"] {
  font-size: 16px;
  border: none;
  border-radius: 20px 0 0 20px;
  outline: none;
  width: 300px;
  height: 20px;
}

.newsletter-form button {
  font-size: 16px;
  border: none;
  border-radius: 0 20px 20px 0;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  width: 100px;
  height: 30px;
}

.newsletter-form input[type="email"]::placeholder {
  color: #aaa;
}

@media (max-width: 768px) {
  .newsletter {
    width: 100%;
    margin-bottom: -100px; /* Adjust for mobile overlap */

  }

  .newsletter-container {
    padding: 0 20px;
  }

  .newsletter-form {
    flex-direction: column;
  }

  .newsletter-form input[type="email"] {
    width: 100%;
    margin-bottom: 10px;
  }

  .newsletter-form button {
    width: 100%;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Optional: Add a slight upward movement */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Bring it back to the original position */
  }
}
.hero-content {
  animation: fadeIn 1s ease-in-out; /* 1s duration, ease-in-out timing */
  opacity: 0; /* Ensure the element is hidden before the animation starts */
  animation-fill-mode: forwards; /* Keep the final state of the animation */
}

.hero-content  {
  animation-delay: 0.2s; /* Delay the animation for a slight staggered effect */
}
.hero-image {
  animation: fadeIn 1s ease-in-out; /* 1s duration, ease-in-out timing */
  opacity: 0; /* Ensure the element is hidden before the animation starts */
  animation-fill-mode: forwards; /* Keep the final state of the animation */
}