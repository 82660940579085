body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #E4C9B6; /* Use global color */
}

header, section, footer {
  box-sizing: border-box;
  width: 100%;
}
