.payment-info {
    padding: 20px;

  }
  
  .content {
    display: flex;
    justify-content: space-between;
  }
  
  .payment-details, .order-summary {
    background-color: #e0f7fa;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: 48%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .payment-details h2, .order-summary h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .payment-details p, .order-summary p {
    font-size: 16px;
    margin: 10px 0;
  }
  
  .instructions {
    font-size: 14px;
    color: #000000;

    margin-bottom: 10px;
  }
  
  .divider {
    margin: 10px 0;
    border: none;
    border-top: 1px solid #ddd;
  }
  
  .upload-label {
    display: inline-block;
    padding: 10px 20px;
    background-color: #00796b;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .upload-label:hover {
    background-color: #004d40;
  }
  
  .upload-label input {
    display: none;
  }
  
  .submit-proof-button {
    width: 100%;
    padding: 10px;
    background-color: #004d40;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-proof-button:hover {
    background-color: #00796b;
  }
  
/* Mobile-specific styles */
@media (max-width: 768px) {
  /* Stack sections vertically on mobile */
  .content {
    flex-direction: column;
  }

  /* Full width for sections on mobile */
  .payment-details, .order-summary {
    width: 90%;
  }

  /* Center and adjust font size for section titles on mobile */
  .payment-details h2, .order-summary h2 {
    font-size: 18px;
    text-align: center;
  }

  /* Adjust font size for paragraphs on mobile */
  .payment-details p, .order-summary p {
    font-size: 14px;
  }

  /* Adjust button and label sizes for easier tapping on mobile */
  .upload-label, .submit-proof-button {
    font-size: 16px;
    padding: 12px;
  }
}
