body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-top: 110px;

}

.sustainability {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.sustainability-header {
  margin-bottom: 40px;
  text-align: center;
}

.sustainability-header h1 {
  font-size: 2.5em;
  color: #000000;
}

.sustainability-header p {
  font-size: 1.2em;
  color: #000000;
  max-width: 800px;
  margin: 0 auto;
}

.sustainability-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.card {
  background-color: #efefef;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.card h2 {
  color: #1dc4bc;
  font-size: 1.5em;
  margin-bottom: 15px;
}

.card p {
  color: #000000;

  font-size: 1em;
  line-height: 1.5;
}

.icon {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .sustainability-header h1 {
    font-size: 2em;
  }

  .sustainability-header p {
    font-size: 1em;
    padding: 0 10px;
  }

  .card h2 {
    font-size: 1.3em;
  }

  .card p {
    font-size: 0.9em;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Optional: Add a slight upward movement */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Bring it back to the original position */
  }
}
.sustainability-header , 
.sustainability-content{
  animation: fadeIn 1s ease-in-out; /* 1s duration, ease-in-out timing */
  opacity: 0; /* Ensure the element is hidden before the animation starts */
  animation-fill-mode: forwards; /* Keep the final state of the animation */
}

.sustainability-header  {
  animation-delay: 0.2s; /* Delay the animation for a slight staggered effect */
}
.sustainability-content  {
  animation-delay: 1s; /* Delay the animation for a slight staggered effect */
}