/* Ensure the container holds both filters and products */
.category-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding: 13px; /* 20px * 0.65 */
  margin-bottom: 50px;
}

/* Adjust the inner container for filters and products */
.category-page .div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px; /* 1200px * 0.65 */
  
}
.filter-price-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px; /* Adjust the gap between elements as needed */
}

.filter-price-container .text-wrapper-7,
.filter-price-container .text-wrapper-8 {
  flex-shrink: 0; /* Prevents the text from shrinking */
}

.filter-price-container .price-range-slider {
  flex-grow: 1; /* Makes the range slider take up available space */
}

/* Filters container on the left */
.category-page .frame-9 {
  width: 20%; /* 20% * 0.65 */
  padding: 13px; /* 20px * 0.65 */
  border-radius: 6.5px; /* 10px * 0.65 */
  box-shadow: 0 2.6px 5.2px rgba(0, 0, 0, 0.1); /* 0 4px 8px * 0.65 */
  background-color: #fff;
  padding-top: 20px;
  max-height: fit-content;
}
.clickable {
  cursor: pointer;
}
.category-page .frame-11 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.category-page .frame-12 {
  left: 0;
  font-family: "Barlow-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  
}
/* Products container on the right */
.category-page .products {
  width: 900px; /* 75% * 0.65 */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 2fr)); /* 200px * 0.65 */
  gap: 13px; /* 20px * 0.65 */
  padding: 13px; /* 20px * 0.65 */

}
.discounttext {
  color: #ff4d4d; /* Red color to highlight the discount */
  font-size: 12px; /* Smaller font size for the discount percentage */
  margin-top: 5px; /* Space between prices and discount percentage */
  display: block; /* Ensure it appears on a new line */
  text-align: right; /* Center the discount percentage */
}

.price-container {
  display: flex;
  align-items: left;
  gap: 5px; /* Space between the original and discount prices */
}

.original-price {
  text-decoration: line-through; /* Strike through the original price */
  color: #888; /* Grey out the original price */
  font-size: 14px; /* Slightly smaller font size for the original price */
}

.discount-price {
  color: #000; /* Ensure the discount price stands out */
  font-weight: bold;
  font-size: 16px; /* Slightly larger font for the discount price */
}


.category-page .group-3 {
  cursor: pointer;
  
  padding: 6.5px; /* 10px * 0.65 */
  text-align: left;
}
.category-page .frame-10 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.category-page .group-3 img {
  width: 100%;
  height: 160px; /* 200px * 0.65 */
  object-fit: cover;
  border-radius: 6.5px; /* 10px * 0.65 */
}

.category-page .text-wrapper-10 {
 width: 75%;
 padding-top: 20px;
}
.category-page .text-wrapper-11 {
  color: #000000;
  font-family: "Barlow-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: auto; /* Let the height adjust based on content */
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 10px;
  white-space: normal; /* Allow the text to wrap */
  word-wrap: break-word; /* Break long words if necessary */
  overflow-wrap: break-word; /* Handle overflow gracefully */
  align-content: left;
  align-self: left;
  align-items: left;
}

.category-page .div-wrapper {
  align-items: center;
  align-self: stretch;
  color: #fff;
  font-size: 12px;
  background-color: #000000;
  border-radius: 40px;
  box-shadow: 0px 5px 5px #00000040;
  display: flex;
  gap: 12px;
  height: 15px;
  justify-content: center;
  overflow: hidden;
  padding: 10px 24px;
  position: relative;
  width: 70%;
  margin-top: 10px;
}
.category-page .group {
  height: 41px;
  margin-right: -4px;
  position: relative;
  width: 100%;
  height: fit-content;
  padding-bottom: 15px;
}
.category-page .text-wrapper-13 {
  font-size: 18px; /* Adjust font-size for better readability on mobile */
  margin-top: 3px; /* Adjust spacing */
  color: #ff0000 !important;
  align-items: left !important;
  align-content: left !important;
}

@media (max-width: 768px) {
  /* Main container should stack elements vertically */
  .category-page {
    display: flex;
    flex-direction: column; /* Ensure filters and products are stacked */
    align-items: center;
    width: 90%;
    padding: 10px;
  }
  
  /* Override the div containing both filters and products to stack them vertically */
  .category-page .div {
    display: flex;
    flex-direction: column; /* Stack filters and products vertically */
    align-items: center;
    width: 85%;
  }

  /* Filters section full-width and on top */
  .category-page .frame-9 {
    width: 100%; /* Full width on mobile */
    margin-bottom: 20px; /* Add space between filters and products */
    padding: 10px; /* Adjust padding for mobile */
    box-shadow: none; /* Optional: Remove box-shadow for cleaner mobile look */
    background-color: #fff;
    align-items: center;
  }

  /* Adjust product grid to display 2 columns */
  .category-page .products {
    width: 85%; /* Full width on mobile */
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns of products */
    gap: 10px; /* Adjust gap between product items */
    align-items: center;

  }

  /* Ensure each product card is responsive */
  .category-page .group-3 {
    padding: 5px; /* Adjust padding for mobile */
    text-align: left; /* Center-align content */
  }

  /* Adjust image size for smaller screens */
  .category-page .group-3 img {
    width: 100%;
    height: 150px; /* Adjust height to fit mobile layout */
    object-fit: cover; /* Ensure images are not distorted */
    border-radius: 5px; /* Adjust border-radius for mobile */
  }

  /* Center text within product cards */
  .category-page .text-wrapper-11,
  
  .category-page .text-wrapper-12 {
    font-size: 11px; /* Adjust font-size for better readability on mobile */
    margin-top: 5px; /* Adjust spacing */
    align-items: left !important;
    align-content: left !important;
  }
  .category-page .text-wrapper-13 {
    font-size: 12px; /* Adjust font-size for better readability on mobile */
    margin-top: 5px; /* Adjust spacing */
    color: #ff0000 !important;
    align-items: left !important;
    align-content: left !important;
  }


  /* Adjust filter options (e.g., categories) for better spacing on mobile */
  .category-page .frame-10 {
    padding: 5px 0; /* Adjust padding for mobile */
    justify-content: space-between;
  }
  
  /* Adjust the Apply Filter and Reset Filter buttons */
  .category-page .div-wrapper {
    width: 100%; /* Ensure buttons are full-width on mobile */
    margin-top: 10px;
    padding: 10px 0;
    font-size: 14px; /* Adjust font size */
  }
  .category-page .bot {
    padding-top: 50px;
  }
  /* Adjust active filters text for better alignment */
  .category-page .text-wrapper-10 {
    width: 100%;
    padding: 10px 0;
    text-align: center; /* Center text on mobile */
  }
}
/* Add new styles for the filter toggle icon */
.filter-toggle-icon {
  display: none;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
}

.filter-toggle-icon svg {
  margin-right: 8px;
}

@media (max-width: 768px) {
  /* Show filter toggle icon on mobile */
  .filter-toggle-icon {
    display: flex;
  }

  /* Hide filters by default on mobile */
  .category-page .frame-9 {
    display: none;
  }

  /* Show filters when they are toggled on */
  .category-page .frame-9.show-filters {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }

  /* Make sure the products section takes full width */
  .category-page .products {
    width: 100%;
  }
}
/* Styles for the price range inputs */
/* Price range inputs with label and input side by side */
.price-range-inputs {
  display: flex;
  flex-direction: column; /* Stack min and max vertically */
  gap: 15px; /* Add space between the min and max price inputs */
  width: 100%; /* Ensure the inputs take full width */
}

.price-range-inputs .input-group {
  display: flex;
  justify-content: flex-start; /* Align label and input to the left */
  gap: 10px; /* Space between label and input */
  align-items: center; /* Align label and input vertically */
}

.price-range-inputs label {
  font-size: 14px; /* Match the rest of the filter text */
  font-weight: 600;
  color: #000; /* Black text for labels */
}

.price-range-inputs input {
  width: 100px; /* Set a fixed width for the number input */
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  margin-left: 20%;
}


@media (max-width: 768px) {
  /* Make sure the layout stays stacked on mobile */
  .price-range-inputs input {
    width: 100%; /* Full width for mobile */
    padding: 5px;
  }

  .price-range-inputs {
    width: 100%; /* Full width for mobile */
  }
}
