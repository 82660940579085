/* src/pages/Cart.css */
.cart-page {
  padding: 20px;

}

.cart-container {
  display: flex;
  justify-content: space-between;
}

.cart-items {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cart-item {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  background-color: #e8f9ff;
  margin-bottom: 10px;
}

.cart-item img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
}

.item-details {
  flex: 1;
  margin-left: 10px;
}

.item-details h2 {
  font-size: 16px;
  margin: 0;
}

.item-details p {
  font-size: 14px;
  color: #000;
}

.quantity-control {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f2f2f2;
  padding: 5px;
  border-radius: 20px;
}

.quantity-control button {
  background: none;
  border: none;
  font-size: 18px;
  color: #000;
  cursor: pointer;
}

.quantity-control span {
  font-size: 16px;
  color: #000;
}

.remove-item {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  margin-left: 10px;
  max-width: 10%;
}

.cart-summary {
  flex: 1;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  margin-left: 20px;
  align-self: flex-start;
}

.cart-summary h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.cart-summary p {
  font-size: 16px;
  margin: 10px 0;
}

.cart-summary button {
  width: 100%;
  padding: 10px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.original-price {
  text-decoration: line-through;
  color: gray;
}

.discounted-price {
  color: red;
  font-weight: bold;
}

.discount-amount {
  color: green;
}


/* Mobile-specific styles */
@media (max-width: 768px) {
  /* Adjust the cart container to stack items vertically on mobile */
  .cart-container {
    flex-direction: column;
    gap: 20px; /* Space between items and summary */
  }

  /* Center-align the cart items and summary */
  .cart-items, .cart-summary {
    width: 100%;
    margin: 0 auto;
  }

  /* Adjust the cart item flex direction for mobile */
  .cart-item {
    flex-direction: column;
    align-items: flex-start;
  }

  /* Center-align item details and quantity control on mobile */
  .item-details, .quantity-control {
    width: 100%;
    margin-left: 0;
    text-align: center;
  }

  /* Remove the margin for the remove button on mobile */
  .remove-item {
    margin-left: 0;
    margin-top: 10px; /* Add space above the remove button */
  }

  /* Adjust cart summary margin */
  .cart-summary {
    margin-left: 0;
    width: 90%;
  }
  .cart-item{
    align-items: center;
  }
}
