/* src/pages/admin/AdminOrders.css */

.admin-orders {
  padding: 20px;
}

.header {
  align-items: center;
}

.filters {
  display: flex;
  gap: 10px;
}

.filters input, .filters select {
  padding: 8px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  text-align: left;
  padding: 8px;
  font-size: 14px;
}

thead th {
  background-color: #f0f8ff;
  border-bottom: 2px solid #ddd;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #f1f1f1;
}

.status {
  padding: 4px 8px;
  border-radius: 4px;
  color: #fff;
  text-align: center;
}

.status.confirmar-pagamento {
  background-color: #ffa500;
}

.status.enviar {
  background-color: #1e90ff;
}

.status.enviado {
  background-color: #32cd32;
}

.status.concluido {
  background-color: #008000;
}

.status.encerrada {
  background-color: #ff0000;
}

.details-button {
  padding: 6px 12px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.details-button:hover {
  background-color: #0056b3;
}
