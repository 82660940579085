/* src/pages/admin/Login.css */
.login {
    width: 100%;
    max-width: 400px;
    margin: 100px auto;
    padding: 20px;
    background: #e0f7fa;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .login h1 {
    margin-bottom: 20px;
  }
  
  .login form {
    display: flex;
    flex-direction: column;
  }
  
  .login form input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .login form button {
    padding: 10px;
    background-color: #00796b;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  