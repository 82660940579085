.thank-you {
  padding: 20px;

}

.thank-you h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.thank-you-message {
  background-color: #e0f7fa;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.thank-you-message p {
  font-size: 16px;
  color: #000000;
  margin: 10px 0;
}

.divider {
  margin: 20px 0;
  border: none;
  border-top: 1px solid #ddd;
}
