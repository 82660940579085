/* SharedStyles.css */

.page-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    margin-bottom: 40px;
    font-family: Arial, sans-serif;
    color: #000000;
    line-height: 1.6;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  }
  
  .page-container h1 {
    font-size: 2.5em;
    color: #000000;
    margin-bottom: 20px;
  }
  
  .page-container h2 {
    font-size: 2em;
    color: #000000;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .page-container p {
    font-size: 1.1em;
    margin-bottom: 20px;
  }
  
  .page-container ul {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .page-container ul li {
    list-style-type: disc;
    margin-bottom: 10px;
  }
  