/* Global styles for the header */
.container {
  max-width: 95%;
  margin: 0 auto;
  padding: 0 20px;
  color: #000000;
  margin-top: 10px;
}
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background: #E4C9B6; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow for a nice effect */
}


/* Header Content */
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  background: rgba(255, 122, 89, 0.0);
  
}

/* Logo */
.header-logo img {
  max-height: 70px;

}


/* Navigation */
.header-nav {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header-nav a {
  text-decoration: none;
  font-weight: 500;
  color: inherit;
  font-size: 15px;
}

/* Dropdown */
.dropdown {
  position: relative;
}

.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  color: inherit;
  font-size: 15px;
  outline: none;
  text-decoration: none;
  padding: 0;
}
.dropdown-toggle:hover {
  background-color: inherit; /* Keep the background color unchanged */
  color: inherit; /* Keep the text color unchanged */
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(255, 255, 255, 0.05)  ;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px 0;
  z-index: 1000;
  display: none; /* Hide by default, controlled by JS */
  flex-direction: column;
  min-width: 200px;
  color:#000;
}

.dropdown:hover .dropdown-menu {
  display: flex; /* Show menu on hover */
  color: #000000;
}

.dropdown-item {
  padding: 10px 20px;
  text-decoration: none;
  color: rgb(6, 6, 6);
  display: block;
}

/* Header Actions */
.header-actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header-cart {
  position: relative;
}

.header-cart a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.header-cart svg {
  fill: #00796b;
  width: 24px;
  height: 24px;
}

.cart-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}

.close-button {
  display: none; /* Hide the close button on larger screens */
}
/* Mobile Styles */
/* Mobile Styles */
@media (max-width: 768px) {
  .header-content {
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0;
  }

  .header-logo {
    flex: 1;
    
  }
  .header-logo img {
    max-height: 60px; /* Adjust the height to make it smaller on mobile */
    padding-top: 10px; /* Optional: Adjust the padding */
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
    color: #000;
    z-index: 101; /* Ensure it stays on top */
    background: none;
    border: none;
    display: block; /* Show on mobile */
  }
  
  .close-button:hover {
    color: #00796b; /* Optional hover color */
  }
  /* Initially off-screen by default */
  .header-nav {
    flex-direction: column;
    align-items: center; /* Center the items horizontally */
    gap: 10px; /* Space between the links */
    background: rgba(255, 255, 255, 0.95);
    padding: 20px;
    position: fixed; /* Fixed so it covers the full screen */
    top: 0;
    right: 0; /* Right of the screen */
    width: 80%; /* Set width to a smaller portion of the screen */
    height: 100vh; /* Full screen height */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100; /* Ensure it stays above other content */
    color: #000;
    transform: translateX(100%); /* Start off-screen */
    opacity: 0; /* Initially hidden */
    visibility: hidden; /* Initially invisible */
    transition: transform 0.3s ease-in-out, opacity 0.5s ease-in-out, visibility 0.5s ease-in-out; /* Smooth slide and fade */
  }

  /* When the menu is shown */
  .header-nav.show {
    transform: translateX(0); /* Slide it into view */
    opacity: 1; /* Fade in */
    visibility: visible; /* Make visible */
  }

  /* Link styling inside the menu */
  .header-nav a {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #000; /* Ensure the color is black */
    display: block;
    padding: 10px 0;
    padding-bottom: 20px;
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out 0.3s;
  }

  /* Show links when menu is visible */
  .header-nav.show a {
    opacity: 1; /* Fade-in effect on links */
  }

  /* Hover effect */
  .header-nav a:hover {
    color: #00796b;
  }

  /* Dropdown menu */
  .dropdown-menu {
    position: static;
    box-shadow: none;
    padding: 0;
  }

  .header-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  .header-cart {
    margin-right: 20px;
  }

  .hamburger-menu {
    display: block;
    cursor: pointer;
    padding-right: 10px;
  }

  .hamburger-menu span {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px;
    background-color: #000;
  }
  .dropdown-toggle {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #000; /* Ensure the dropdown toggle text is black */
    display: block;
    padding: 10px 0;
    padding-bottom: 20px;
    width: 100%;
    opacity: 1;
  }
  .dropdown {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #000000;
   }

}
