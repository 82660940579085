.footer {
  padding: 40px;
  background-color: #fff;
  width: 100%;
  z-index: 1;
}

.footer-columns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.footer-column {
  flex: 1 1 200px;
  margin: 20px;
}

.footer-column h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  text-decoration: none;
  color: #000; /* Black text color */
}

.footer-column ul li a:hover {
  color: #1e90ff; /* Blue color on hover */
}
