.shipping-info {
    padding: 20px;
    margin: 0 20px; /* Add margin to the left and right */

  }
  
  .form-container {
    display: flex;
    justify-content: space-between;
  }
  
  .left-section, .right-section {
    width: 48%;
    background-color: #f0f0f0; /* Light background color for sections */
    border: 1px solid #ddd; /* Border around sections */
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Padding inside sections */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  .left-section {
    flex: 3; /* Grow more */
  }
  
  .right-section {
    flex: 2; /* Grow less */
  }
  
  .left-section label, .right-section label {
    display: block;
    margin-bottom: 10px;
  }
  
  input[type="email"], input[type="text"], select {
    width: calc(100% - 20px);
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  input[type="radio"] {
    margin-right: 10px;
  }
  
  .subtitle {
    font-size: 12px;
    color: #000000;
    ;
    margin-left: 25px; /* Indent subtitle under radio button */
  }
  
  .order-summary {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .order-summary h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .summary-row {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
  }
  
  .payment-method {
    margin-top: 20px;
  }
  
  .payment-method h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .payment-method label {
    display: block;
    margin-bottom: 10px;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #333;
  }
  @media (max-width: 768px) {
    /* Stack sections vertically */
    .form-container {
      flex-direction: column;
      align-items: center;
    }
  
    /* Ensure sections take full width on mobile */
    .left-section, .right-section {
      width: 100%;
      margin-bottom: 20px; /* Add space between sections */
    }
  
    /* Adjust input fields to take full width */
    input[type="email"], input[type="text"], select {
      width: 100%;
    }
  
    /* Center align summary rows and payment method labels */
    .summary-row, .payment-method label {
      justify-content: space-between;
      text-align: left;
    }
  
    /* Adjust button width on mobile */
    button {
      width: 100%;
    }
  }
  