.product-detail {
    display: flex;
    padding: 20px;
    padding-top: 80px;
    margin-left: 80px;
    margin-bottom: 70px;
    
  }
  
  .image-gallery {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 50%;
  }
  
  .thumbnail-container {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }
  
  .thumbnail {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 10px;
  }
  
  .thumbnail:hover {
    border: 2px solid #00796b;
  }
  
  .main-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 400px;
  }
  
  .main-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px; /* Rounded corners */
  }
  
  .product-info {
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    white-space: pre-line; /* This will preserve the line breaks */

  }
  
  .product-info h1 {
    align-content: left;
    text-align: left; /* Center the product name */

    font-size: 2.5em;
    color: #000000;
  }
  
  .product-info p {
    font-size: 1.2em;
    color: #000000;
  }
  .email-notification{
    max-width: 50%;
  }
  .price {
    font-size: 2em;
    color: #000000;
    margin-top: 10px;
  }
  
  .original-price {
    text-decoration: line-through;
    color: #b0bec5;
    margin-right: 10px;
  }
  
  .discount {
    font-size: 1.5em;
    color: #d32f2f;
  }
  .quantity-cart-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .quantity-container {
    display: flex;
    align-items: center;
    margin-right: 20px;
    background-color: #f2f2f2;
    border-radius: 25px;
    padding: 0; /* Remove padding to directly control height */
    height: 56px; /* Set a fixed height */
    overflow: hidden; /* Ensure no extra space is visible */
  }
  
  .quantity-container button {
    background-color: transparent;
    border: none;
    color: #000;
    font-size: 1.5em;
    cursor: pointer;
    height: 100%; /* Ensure buttons take full height */
    width: 80px; /* Set a fixed width for consistency */
    outline: none; /* Remove outline */
  }
  
  .quantity-container button:hover {
    color: #000; /* Ensure no color change on hover */
    background-color: transparent; /* Ensure no background change on hover */
  }
  
  .quantity-container input {
    text-align: center;
    border: none;
    font-size: 1.2em;
    background-color: transparent;
    height: 100%; /* Ensure input takes full height */
    outline: none; /* Remove the outline around the input */
    box-shadow: none; /* Remove any box-shadow */
    max-width: 60px;
  }
  
  .add-to-cart {
    background-color: #000;
    color: white;
    border: none;
    padding: 0 20px; /* Adjust padding, but no top/bottom padding */
    font-size: 1.2em;
    cursor: pointer;
    border-radius: 25px; /* Consistent border radius */
    height: 56px; /* Match height with quantity container */
    display: flex;
    align-items: center; /* Center the text vertically */
    justify-content: center; /* Center the text horizontally */
  }
  
  .add-to-cart:hover {
    background-color: #333;
  }
  
  
  .pag {
    position: relative;
    z-index: 10px;
  }

  @media (max-width: 768px) {
    /* Main product detail container adjustments */
    .product-detail {
      display: flex;
      flex-direction: column;
      padding: 15px;
      margin: 0 auto; /* Center the content */
      width: 90%; /* Ensure full width on mobile */
      align-items: center;
    }
  
    /* Main image container */
    .image-container {
      width: 100%; /* Full width for the main image */
      height: auto; /* Auto height based on aspect ratio */
      margin-bottom: 15px; /* Add spacing below the main image */
    }
    .image-gallery {
    width: 100%;
    }
    .main-image {
      width: 100%; /* Ensure main image fits the container */
      height: fit-content; /* Maintain aspect ratio */
      border-radius: 10px; /* Soft rounded corners for a modern look */
    }

    /* Thumbnail container */
    .thumbnail-container {
      width: 20%;
      display: flex;
      justify-content: center; /* Center thumbnails */
      margin-bottom: 15px; /* Add spacing below thumbnails */
    }
  
    .thumbnail {
      width: 60px; /* Set a fixed size for thumbnails */
      height: 60px; /* Maintain aspect ratio */
      margin: 0 5px; /* Add space between thumbnails */
      border-radius: 5px; /* Slightly rounded corners */
    }
  
    /* Product name styling */
  
    .product-info {
      text-align: left; /* Center the product name */

      width: 95%;
    }
  
    .product-info h1 {
      font-size: 1.8em; /* Slightly larger font for readability */
      margin-bottom: 10px; /* Add space below the title */
      text-align: left; /* Center the product name */
      color: #333; /* Darker color for better readability */
    }
  
    /* Price and discount styling */
    .price,
    .discount {
      display: flex;
      font-size: 1.5em; /* Adjust font size */
      margin-top: 10px;
      text-align: center; /* Center the text */
      max-width: 45%;
    }
  
    .price {
      margin-right: 10px; /* Add space between price and discount */
      color: #000000; /* Attractive primary color for the price */

    }
  
    .discount {
      color: #d32f2f; /* Bright color for the discount to make it stand out */

    }
  
    /* Align price and discount */
    .product-info .price-container {
      display: flex;
      justify-content: center; /* Center the price and discount */
      margin-bottom: 15px; /* Add space below the price section */
    }
  
    /* Quantity and Add to Cart button styling */
    .quantity-cart-container {
      display: flex;
      align-items: center; /* Align vertically */
      width: 100%;
      margin-bottom: 20px; /* Add space below this section */
    }
  
    .quantity-container {
      display: flex;
      align-items: center;
      background-color: #f2f2f2;
      border-radius: 25px;
      flex-grow: 1; /* Allow the quantity container to take available space */
      max-width: 120px; /* Limit the size of the quantity container */
      max-height: 60px;
    }
  
    .quantity-container button {
      font-size: 1em; /* Make the buttons larger for easier tapping */
    }
  
    .quantity-container input {
      font-size: 1em; /* Adjust font size */
      text-align: center; /* Center the input text */
      max-width: 50px;
    }
  
    .add-to-cart {
      background-color: #000;
      color: white;
      border: none;
      padding: 12px 20px; /* Adjust padding for the button */
      font-size: 1.1em; /* Slightly larger font for better readability */
      cursor: pointer;
      border-radius: 25px; /* Smooth, rounded corners */
      margin-left: 10px; /* Add space between quantity and button */
      flex-grow: 2; /* Ensure button takes up more space */
    }
  
    .add-to-cart:hover {
      background-color: #333; /* Slight hover effect */
    }
  
    /* Product description styling */
    .product-info p {
      font-size: 1em; /* Standard font size for readability */
      line-height: 1.5em; /* Improve line spacing for readability */
      text-align: justify; /* Justify text for a cleaner look */
      color: #555; /* Softer text color */
      margin-top: 10px; /* Add space above the description */
      width: 100%; /* Full width */
    }
  
  }
  
  .popup-notification {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #28a745;
    color: white;
    padding: 15px 30px;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: opacity 0.3s ease;
  }
  