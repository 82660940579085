.faq {
    padding: 20px;
    text-align: center;

  }
  
  .faq-title {
    font-size: 2.5em;
    margin-bottom: 40px;
  }
  
  .faq-item {
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  .faq-question {
    font-size: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .arrow {
    font-size: 12px;
    transition: transform 0.3s ease;
  }
  
  .faq-item.active .arrow {
    transform: rotate(180deg);
  }
  
  .faq-answer {
    margin-top: 20px;
    text-align: left;
    font-size: 0.8em;
  }
  
  @media (max-width: 768px) {
    .faq-title {
      font-size: 2em;
    }
  
    .faq-question {
      font-size: 1em;
    }
  }
  